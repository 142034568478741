import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { DataService } from './services/data.service';
import { AuthGuard } from './common/auth.guard';
import { ProgressLoaderService } from './services/loader.service';
import { FullscreenLayoutComponent } from './layouts/fullscreen-layout/fullscreen-layout.component';
import { UserService } from './services/user.service';
import { StoreManagerService } from './services/store-manager.service';
import { OrderService } from './services/order.service';
import { AngularFireDatabaseNocFactory, AngularFireDatabaseNocService } from './services/fire.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { provideUserIdleConfig } from 'angular-user-idle';
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core'

Bugsnag.start({ apiKey: 'fbf7bcc8b2803c4bb13196aec3b55541' })
BugsnagPerformance.start({ apiKey: 'fbf7bcc8b2803c4bb13196aec3b55541' })

// create a factory which will return the Bugsnag error handler
// export function errorHandlerFactory() {
//     return new BugsnagErrorHandler()
// }


@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    FullscreenLayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
  ],
  providers: [
    AuthGuard,
    StoreManagerService, OrderService, DataService, ProgressLoaderService, UserService,
    provideUserIdleConfig({ idle: 60 * 60, timeout: 5, ping: 120 }),
    // { provide: ErrorHandler, useFactory: errorHandlerFactory },
    { provide: AngularFireDatabaseNocService, deps: [PLATFORM_ID, NgZone], useFactory: AngularFireDatabaseNocFactory },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
